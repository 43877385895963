@import '../../../../scss/theme-bootstrap';

.site-footer {
  &__sticky {
    @include transition(bottom 0.2s ease-in);
    @include animation-duration(1s);
    @include animation-fill-mode(both);
    @include font-smoothing(antialiased);
    position: fixed;
    z-index: 10;
    padding: 5px 12px 0;
    text-align: center;
    color: $white;
    height: auto;
    border-bottom: none;
    width: 49%;
    $ldirection: 0;
    font-size: 13px;
    bottom: 0;
    background: $color-off-black;
    @include breakpoint($footer-breakpoint) {
      $ldirection: 20px;
      width: 215px;
      padding-bottom: 12px;
      font-size: 15px;
    }
    &--offer {
      $ldirection: auto;
      $rdirection: 0;
      @include breakpoint($footer-breakpoint) {
        $rdirection: auto;
        $ldirection: 245px;
      }
    }
    &.sticky-livechat {
      z-index: 3;
    }
    a {
      color: $white;
      &:hover {
        text-decoration: none;
      }
    }
    h3,
    .at-h3__typography {
      @include breakpoint($footer-breakpoint) {
        margin-bottom: 4px;
      }
    }
    p {
      margin: 0 auto 8px;
      text-transform: none;
      font-size: 14px;
      font-weight: 300;
    }
    // These have .js-footer-sticky-reveal
    .sticky-offer__copy,
    .sticky-livechat__copy,
    .sticky-livechat__integration {
      display: none;
    }
    .sticky-livechat__integration,
    .sticky-offer__copy {
      padding-bottom: 10px;
    }
    .sticky-livechat__integration {
      padding-bottom: 15px;
      margin-top: -6px;
    }
    @include breakpoint($mobile-only) {
      body.page-product & {
        display: none;
      }
    }
  }
}
